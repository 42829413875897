import React, { FC } from 'react'
import { Grid, Box, Heading, Container, Paragraph } from '@theme-ui/components'
import { Layout } from '../components/layout/layout'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { ProfileCard } from '../components/profile-card/profile-card'
import { IAthlete } from '../types/athletes'
import { SEO } from '../components/seo/seo'
import { InternalLink } from '../components/internal-link/internal-link'

type AthletesPageProps = {
  allContentfulAthlete: {
    nodes: IAthlete[]
  }
}

const AthletesPage: FC<PageProps<AthletesPageProps>> = ({ data }) => {
  const { allContentfulAthlete } = data

  return (
    <Layout>
      <SEO title="Athletes" />
      <Box mt={5} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            Current Athletes
          </Heading>
        </Container>
      </Box>
      <Box my={[4, 5]}>
        <Container>
          <Grid columns={[2, 2, 4]} gap={[4, 4, 5]}>
            {allContentfulAthlete.nodes
              .filter((athlete) => athlete.managed && !athlete.contractEnd)
              .map((athlete) => (
                <ProfileCard
                  key={`athlete-` + athlete.slug}
                  name={athlete.name}
                  team={athlete.teamName}
                  renderImage={
                    <Box sx={{ borderRadius: '50%', overflow: 'hidden' }}>
                      <GatsbyImage
                        image={athlete.profileImage?.gatsbyImageData}
                        alt={athlete.name || ``}
                        style={{ borderRadius: '50%' }}
                        imgStyle={{ borderRadius: '50%' }}
                      />
                    </Box>
                  }
                  url={`/athletes/${athlete.slug}/`}
                />
              ))}
          </Grid>
        </Container>
      </Box>
      <Box mt={6} sx={{ textAlign: 'center' }}>
        <Container>
          <Heading
            sx={{
              fontSize: 5,
              fontWeight: 500,
              textTransform: 'uppercase',
            }}
          >
            Former clients include:
          </Heading>
        </Container>
      </Box>
      <Box my={4}>
        <Container>
          <Paragraph>
            Dan, Gee and Rachel Atherton (GBR), Sam Blenkinsop (NZL), George
            Brannigan (NZL), Cadel Evans (AUS), Brendan Fairclough (GBR),
            Mathias Flückiger (SUI), Laurie Greenland (GBR), Bernard Kerr (GBR),
            Matti Lehikoinen (FIN), Kashi Leuchs (NZL), Justin Leov (NZL), Dean
            Lucas (AUS), Greg Minnaar (RSA), Neko Mulally (USA), Clay Porter
            (USA), Kyle Strait (USA), Angel Suarez (ESP), David Vazquez (ESP),
            Greg Williamson (SCO), Cameron Wright (AUS), Cam Zink (USA).
          </Paragraph>
        </Container>
      </Box>
      <Container sx={{ textAlign: 'center' }}>
        <InternalLink url="/locker/login">
          <StaticImage
            src="../images/black-betty-large.jpg"
            alt=""
            width={64}
          />
        </InternalLink>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query AthletesPageQuery {
    allContentfulAthlete(sort: { order: ASC, fields: [contractStart] }) {
      nodes {
        name
        slug
        teamName
        managed
        profileImage {
          title
          gatsbyImageData(width: 720, aspectRatio: 1, placeholder: BLURRED)
        }
      }
    }
  }
`

export default AthletesPage
